import React from 'react';
import {
    Avatar,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';
import {
    Facebook,
    Instagram,
    LinkedIn,
    Twitter,
    YouTube,
} from "@mui/icons-material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useCareerContext } from 'contexts/CareerContext';

const CareerSidePanel = () => {
    const {
        navOptions,
        setSelectedNavOption,
        setSelectedSubNavOption,
        isSidebarVisible,
        toggleSidebar,
        isSmallScreen,
        attempts,
        role
    } = useCareerContext();

    const [expanded, setExpanded] = React.useState(null);
    const modules = {};

    // Handle accordion expansion for main navOptions
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleOptionClick = (mainOption, subOption) => {
        const upperMainOption = mainOption.toUpperCase();
        const currentAttempt = attempts[upperMainOption]; // Use the attempts for the current mainOption

        // Student-specific logic to check the ability to attempt tests
        if (role === 'student') {
            // Disable 'Take test' if 'canAttempt' is false
            if (subOption === 'Take test' && (!currentAttempt || !currentAttempt.canAttempt)) {
                return; // Disable click
            }

            // Disable Career Guidance and View Result if 'attemptCount' is 0
            if ((subOption === 'Career Guidance' || subOption === 'View Result') && currentAttempt?.attemptCount === 0) {
                return; // Disable click
            }
        }

        // Set the selected main and sub-options
        setSelectedNavOption(mainOption);
        setSelectedSubNavOption(subOption || 'Classes'); // Set default subOption if not provided

        // Toggle the sidebar only on small screens
        if (isSmallScreen) {
            toggleSidebar();
        }
    };

    return (
        <div className={`options-progress-panel ${isSidebarVisible ? '' : 'hidden'}`}>
            <div className="heading-wrapper">
                <h2 className="main-heading">Career</h2>
                <h3 className="sub-heading">Progress</h3>
            </div>

            <div className="options-list">
                {Object.keys(navOptions).map((mainOption, index) => (
                    navOptions[mainOption].length > 0 ? (
                        <Accordion
                            key={index}
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            className="custom-accordion-option"
                            sx={{
                                overflow: "hidden",
                                "&:not(:last-of-type)": {
                                    borderRadius: "15px",
                                },
                                "&:not(:first-of-type)": {
                                    borderRadius: "15px",
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ArrowForwardIosIcon
                                        sx={{ fontSize: "medium", color: "white" }}
                                    />
                                }
                                className="accordion-summary"
                                sx={{
                                    backgroundColor: "#2356fe",
                                    cursor: "pointer",
                                }}
                            >
                                <Typography className="accordion-title" sx={{ color: "white" }}>
                                    {mainOption}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                {/* Render sub-options under each main option */}
                                {navOptions[mainOption].map((subOption, subIndex) => {
                                    const upperMainOption = mainOption.toUpperCase();
                                    const currentAttempt = attempts[upperMainOption];

                                    // Determine if the subOption should be disabled based on the conditions
                                    const isDisabled = role === 'student' && (
                                        (subOption === 'Take test' && (!currentAttempt || !currentAttempt.canAttempt)) ||
                                        (subOption === 'Career Guidance' || subOption === 'View Result') && currentAttempt?.attemptCount === 0
                                    );

                                    // Test Cards should always be enabled, no need to disable it
                                    return (
                                        <Typography
                                            key={subIndex}
                                            onClick={() => !isDisabled && handleOptionClick(mainOption, subOption)}
                                            sx={{
                                                padding: "10px 20px",
                                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                color: isDisabled ? '#b0b0b0' : 'inherit',
                                                "&:hover": !isDisabled && { backgroundColor: "#f1f1f1" }
                                            }}
                                        >
                                            {subOption}
                                        </Typography>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Accordion
                            key={index}
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            className="custom-accordion-option"
                            sx={{
                                overflow: "hidden",
                                "&:not(:last-of-type)": {
                                    borderRadius: "15px",
                                },
                                "&:not(:first-of-type)": {
                                    borderRadius: "15px",
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ArrowForwardIosIcon
                                        sx={{ fontSize: "medium", color: "white" }}
                                    />
                                }
                                className="accordion-summary"
                                sx={{
                                    backgroundColor: "#2356fe",
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    handleOptionClick(mainOption)
                                }
                            >
                                <Typography className="accordion-title" sx={{ color: "white" }}>
                                    {mainOption}
                                </Typography>
                            </AccordionSummary>
                        </Accordion>
                    )
                ))}
            </div>

            <div className="social-media-section">
                <div className="d-flex align-items-center justify-content-center">
                    <Avatar
                        alt={modules?.author}
                        src={"/android-chrome-192x192.png"}
                        sx={{ width: 66, height: 66 }}
                        className="me-4"
                    />
                    <h5 className="author-name">{modules?.author}</h5>
                </div>
                <div className="d-flex justify-content-center">
                    <IconButton
                        href="https://www.facebook.com/profile.php?id=100089167461647&mibextid=ZbWKwL"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <Facebook sx={{ color: "blue" }} />
                    </IconButton>
                    <IconButton
                        href="https://twitter.com/Yuvamanthan_org"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <Twitter sx={{ color: "skyblue" }} />
                    </IconButton>
                    <IconButton
                        href="https://www.linkedin.com/in/yuva-manthan-09aa2025b/"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <LinkedIn sx={{ color: "blue" }} />
                    </IconButton>
                    <IconButton
                        href="https://www.instagram.com/yuvamanthan_org/"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <Instagram sx={{ color: "tomato" }} />
                    </IconButton>
                    <IconButton
                        href="https://www.youtube.com/@yuvamanthan_"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <YouTube sx={{ color: "red" }} />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default CareerSidePanel;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, Tooltip, IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer, LabelList } from 'recharts';
import AboutRiasec from './AboutRiasec';
import { useCareerContext } from 'contexts/CareerContext';
import { useGlobalContext } from 'global/context';
import { Popup } from 'layout/Popup';

const RIASECFullForm = {
    r: "Realistic",
    a: "Artistic",
    i: "Investigative",
    s: "Social",
    e: "Enterprising",
    c: "Conventional"
};

const CompleteRiasec = () => {
    const { role, getScore } = useCareerContext(); // Access context
    const { userData } = useGlobalContext();
    const [categoryScores, setCategoryScores] = useState({});

    useEffect(() => {
        const fetchCategoryScores = async () => {
            if (userData) {
                try {
                    const scores = await getScore(userData.id, role);
                    if (scores) {
                        setCategoryScores(scores);
                    } else {
                        Popup("error", "", "No scores received. Please try again.", 3000);
                    }
                } catch (error) {
                    console.error("Error fetching scores:", error);
                    Popup("error", "", "Error occurred while fetching scores. Please try again.", 3000);
                }
            }
        };

        fetchCategoryScores();
    }, [getScore, userData, role]);


    const data = [
        { name: 'Realistic', score: categoryScores.r },
        { name: 'Artistic', score: categoryScores.a },
        { name: 'Investigative', score: categoryScores.i },
        { name: 'Social', score: categoryScores.s },
        { name: 'Enterprising', score: categoryScores.e },
        { name: 'Conventional', score: categoryScores.c },
    ];

    const getTopThreeCategories = (scores) => {
        return Object.keys(scores).sort((a, b) => scores[b] - scores[a]).slice(0, 3);
    };

    const topThreeCategories = getTopThreeCategories(categoryScores);

    return (
        <Box
            sx={{
                position: "relative",
                padding: "2rem 0",
                boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                borderRadius: "1rem",
                border: "5px solid #2356fe",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem",
                minHeight: "80vh",
                width: "100%",
                overflow: "scroll",
                textAlign: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "0 1rem",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: "left",
                        fontWeight: "bold",
                        color: "#2356fe",
                        fontSize: { xs: "2rem", sm: "2.5rem" },
                    }}
                >
                    Assessment Report
                </Typography>
            </Box>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <XAxis type="number" domain={[0, 7]} />
                    <YAxis type="category" dataKey="name" width={100} />
                    <RechartsTooltip />
                    <Bar dataKey="score" fill="#2356fe">
                        <LabelList dataKey="score" position="insideRight" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2356fe', mb: 2 }}>
                Your Top 3 Interest Codes
            </Typography>
            <Grid container spacing={2} justifyContent="center" p={2}>
                {topThreeCategories.map((code) => (
                    <Grid item sm={12} lg={3} key={code}>
                        <Card sx={{
                            padding: 1,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#C7D4FF',
                            border: '2px solid #2356fe',
                        }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2356fe' }}>
                                    {RIASECFullForm[code]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <AboutRiasec />
        </Box>
    );
};

export default CompleteRiasec;

import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Pagination, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { useCareerContext } from 'contexts/CareerContext';
import DownloadIcon from '@mui/icons-material/Download';

import { apiJson } from 'api';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const StudentListView = () => {
    const { classInfo, studentsList, setSelectedSubNavOption, setSelectedStudentId } = useCareerContext();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('all');
    const itemsPerPage = 15;  // Display 15 records per page
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchCareersAndFeedbacks = async (student) => {
        try {
            // const [careersResponse, feedbackResponse] = await Promise.all([
            //     apiJson(`/api/v2/riasecTest/riasec-careers/${student.id}`),
            //     apiJson(`/api/v2/feedbacks/${student.id}`)
            // ]);
            const [careersResponse] = await Promise.all([
                apiJson(`/api/v2/riasecTest/riasec-careers/${student.id}`),
                // apiJson(`/api/v2/feedbacks/${student.id}`)
            ]);
            const careers = careersResponse?.data?.result;
            // const feedbacks = feedbackResponse.data;
            return {
                newGenCareers: careers.slice(0, 3).map(c => c.careerName).join(', '),
                traditionalCareers: careers.slice(3).map(c => c.careerName).join(', '),
                // feedbacks: feedbacks.map(fb => fb.comment).join(' | ')
                feedbacks: "",
            };
        } catch (error) {
            console.error(`Error fetching data for student ${student.id}:`, error);
            return { newGenCareers: '', traditionalCareers: '', feedbacks: '' };
        }
    };

    useEffect(() => {
        const filtered = studentsList.filter(student => {
            const fullName = (student.first_name + ' ' + (student.last_name || '')).toLowerCase().replace(/\s+/g, '');
            const matchesSearch = fullName.includes(searchQuery.toLowerCase().replace(/\s+/g, ''));

            const matchesFilter = filter === 'all' || (filter === 'attempted' && student.result) || (filter === 'notAttempted' && !student.result);
            return matchesSearch && matchesFilter;
        });
        setFilteredStudents(filtered);
        setCsvData(filtered);  // Update csvData with filtered students
        setCurrentPage(1);
    }, [searchQuery, studentsList, filter]);

    const paginatedStudents = filteredStudents.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const downloadCSV = async () => {
        setLoading(true);

        const csvRows = await Promise.all(csvData.map(async (student, index) => {
            if (student.result) {
                // Sort the result keys by score in descending order
                const resultKeys = Object.keys(student.result)
                    .sort((a, b) => student.result[b] - student.result[a])
                    .map(key => `${key.toUpperCase()}: ${student.result[key]}`);

                const { newGenCareers, traditionalCareers, feedbacks } = await fetchCareersAndFeedbacks(student);

                return [
                    student.id,
                    student.first_name + " " + (student.last_name || ""),
                    student.father_name,
                    `"${resultKeys.join(', ')}"`,
                    `"${newGenCareers}"`,
                    `"${traditionalCareers}"`,
                    feedbacks
                ];
            } else {
                return [
                    student.id,
                    student.first_name + " " + (student.last_name || ""),
                    student.father_name,
                    '',
                    '',
                    '',
                    ''
                ];
            }
        }));
        const headers = ['Id', 'Student Name', 'Father Name', 'Personality Traits', 'New Generation Careers', 'Traditional Careers', 'Teacher Feedbacks'];
        const csvContent = [headers, ...csvRows].map(e => e.join(',')).join('\n');
        const className = [classInfo?.className];

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${className}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setLoading(false);
    };


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleViewResult = (id) => {
        setSelectedStudentId(id);
        setSelectedSubNavOption('View Result');
    };

    return (
        <Box sx={{ padding: 4 }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}

            {/* Search and Filter Container */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
                <TextField
                    label="Search by Name"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ marginRight: 2, flex: 1 }}
                />

                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel>Filter</InputLabel>
                    <Select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        label="Filter"
                    >
                        <MenuItem value="all">All Students</MenuItem>
                        <MenuItem value="attempted">Attempted Test</MenuItem>
                        <MenuItem value="notAttempted">Not Attempted Test</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    onClick={downloadCSV}
                    startIcon={<DownloadIcon />}
                    sx={{
                        backgroundColor: 'green',
                        padding: '1rem',
                        color: 'white',
                        borderColor: 'green',
                        '&:hover': {
                            backgroundColor: 'white',
                            color: 'green',
                            borderColor: 'green',
                        },
                        marginLeft: 2,
                        textTransform: "Capitalize",
                    }}
                    disabled={loading}
                >
                    Detailed Data
                </Button>
            </Box>

            {/* Students List Table */}
            <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                <Table sx={{ minWidth: 650 }} aria-label="students table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>S No.</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Student Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Parent Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Result</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Personalities in Descending Order</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedStudents.length ? (
                            paginatedStudents.map((student, index) => {
                                const topPersonalities = student.result
                                    ? Object.keys(student.result).sort((a, b) => student.result[b] - student.result[a]).slice(0, 6).map(key => key.toUpperCase())
                                    : [' ', ' ', ' ', ' ', ' ', ' '];

                                return (
                                    <TableRow key={student.id}>
                                        <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                                        <TableCell>{student.first_name + ' ' + (student.last_name || '')}</TableCell>
                                        <TableCell>{student.father_name}</TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{
                                                    background: '#2356fe',
                                                    color: "#ffffff",
                                                    textAlign: "center",
                                                }}
                                                variant="contained"
                                                onClick={() => handleViewResult(student.id)}
                                                disabled={!student.result}
                                            >
                                                See Result
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Grid
                                                container
                                                spacing={1}  // Controls the gap between boxes
                                                sx={{
                                                    '@media (min-width: 200px)': {     // Default behavior, show 6 boxes when width is >= 200px
                                                        gridTemplateColumns: 'repeat(6, 1fr)',
                                                    },
                                                    '@media (max-width: 199px)': {     // When width < 200px, show 3 boxes
                                                        gridTemplateColumns: 'repeat(3, 1fr)',
                                                    },
                                                }}
                                            >
                                                {topPersonalities.slice(0, 6).map((personality, idx) => (  // Ensure max 6 items are shown
                                                    <Grid
                                                        item
                                                        key={idx}
                                                        xs={4}   // Use xs={4} to ensure 3 boxes per row in a 12-column grid (12 / 3 = 4)
                                                        sm={2}   // Use sm={2} to ensure 6 boxes per row in a 12-column grid (12 / 6 = 2)
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '30px',
                                                                height: '30px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textAlign: 'center',
                                                                color: '#ffffff',
                                                                background: "#2356fe",
                                                                borderRadius: '6px',
                                                            }}
                                                        >
                                                            {personality}
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No students found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            {
                filteredStudents.length > itemsPerPage && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={Math.ceil(filteredStudents.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                )
            }
        </Box>
    );
};

export default StudentListView;

import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { apiJson } from 'api';
import { useGlobalContext } from 'global/context';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';
import { toast } from 'react-toastify';

const CareerAdviceSection = () => {
    const [careers, setCareers] = useState([]);
    const [selectedCareer, setSelectedCareer] = useState(null);
    const [careerDetails, setCareerDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { userData } = useGlobalContext();

    const fetchCareers = async () => {
        setIsLoading(true);
        try {
            const response = await apiJson(`api/v2/riasecTest/riasec-careers/${userData?.id}`);
            if (response.data?.result?.length) {
                setCareers(response.data.result);
                setError(null);
                setSelectedCareer(response.data.result[0]);
                setCareerDetails(response.data.result[0]);
            } else {
                setError('No careers available.');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError('Could not fetch the Careers list. Please try again later.');
            toast.error('Could not fetch the Careers list. Please try again later.');
            console.error('Error fetching careers:', error);
        }
    };

    const fetchCareerDetails = (careerId) => {
        const career = careers.find(c => c.careerId === careerId);
        if (career) {
            setCareerDetails(career);
        }
    };

    useEffect(() => {
        if (userData?.id) {
            fetchCareers();
        }
    }, [userData]);

    return (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2356fe', mb: 2 }}>
                Career Guidance
            </Typography>

            {isLoading ? (
                <YuvaLoader setShow={isLoading} show={isLoading} />
            ) : careers.length > 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        {careers.map((career) => (
                            <Card
                                key={career.careerId}
                                onClick={() => {
                                    setSelectedCareer(career);
                                    fetchCareerDetails(career.careerId);
                                }}
                                sx={{
                                    cursor: 'pointer',
                                    marginBottom: '1rem',
                                    border: selectedCareer?.careerId === career.careerId ? '2px solid #2356fe' : '2px solid #fff',
                                    '&:hover': { border: '2px solid #2356fe' },
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2356fe' }}>
                                        {career.careerName}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>

                    <Grid item xs={12} md={8}>
                        {careerDetails ? (
                            <Box sx={{ padding: '1rem', backgroundColor: '#e6f0ff', borderRadius: '0.5rem', textAlign: "left" }}>
                                <Typography
                                    variant="h5"
                                    sx={{ fontWeight: 'bold', color: '#2356fe', mb: 2, textAlign: 'center' }}
                                >
                                    {careerDetails.careerName}
                                </Typography>

                                {/* Render the Markdown content */}
                                <ReactMarkdown
                                    children={careerDetails.careerDetails}
                                    components={{
                                        h1: ({ node, ...props }) => <Typography variant="h4" sx={{ color: '#2356fe' }} {...props} />,
                                        h2: ({ node, ...props }) => <Typography variant="h5" sx={{ color: '#2356fe', mt: 2 }} {...props} />,
                                        p: ({ node, ...props }) => <Typography variant="body1" sx={{ mb: 1 }} {...props} />,
                                        ul: ({ node, ...props }) => <ul style={{ paddingLeft: '1.5rem' }} {...props} />,
                                        li: ({ node, ...props }) => (
                                            <Typography component="li" variant="body1" sx={{ mb: 0.5 }} {...props} />
                                        ),
                                    }}
                                />
                            </Box>
                        ) : (
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2356fe' }}>
                                Please select a career to see the details.
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#f44336', mt: 4 }}>
                        {error || 'No careers available at the moment. Please try again later.'}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={fetchCareers}
                    >
                        Try Again
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default CareerAdviceSection;
